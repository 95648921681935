<template>
  <div class="main finger border-bg">
    <div class="border-tit">当前位置 > 首页 > 联系我们</div>
   <div class="border-tit2">{{info.title}}</div>
    <div class="border-tit-info flex">
      <div><span>浏览次数：</span>{{info.pv}}</div>
      <!-- <div><span>日期时间：</span>{{info.pv}}</div> -->
    </div>
    <div class="content" v-html="info.content"></div>
  </div>
</template>
<script>
import { positionDetail } from "@/api/apiData";
export default {
  data(){
    return{
      info:""
    }
  },
  mounted() {
    this.$bus.emit("navS", 8);
       positionDetail({posid:2}).then(res=>{
      if(res.code==1){
         this.info = res.data
      }else{
        this.$message.error(res.msg);
      }
    })
  },
};
</script>
<style lang="less" scoped>
.finger {
  margin-top: 20px;
}
</style>